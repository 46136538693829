import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Support = () => (
  <Section>
    <StyledContainer>
      <SectionTitle>{"Support"}</SectionTitle>
      <FeatureText>
        Si vous avez besoin d'aide pour prendre en main l'application mobile
        Thanatech, vous pouvez nous contacter à l'adresse <a
        href="mailto:support@thana.tech"><b>support@thana.tech</b></a>.
      </FeatureText>
    </StyledContainer>
  </Section>
)

export default Support

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: left;
`
