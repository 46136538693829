import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import PrivacyPolicyNavigation
  from "../components/common/navigation/privacy-policy-navigation"
import Support from "../components/sections/support"

const SupportPage = () => (
  <Layout>
    <SEO title="Thanatech" description="Support" />
    <PrivacyPolicyNavigation />
    <Support />
  </Layout>
)

export default SupportPage
